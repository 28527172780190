import * as React from 'react';
import { useLocaleString } from '@devsisters/gatsby-plugin-i18n';

const InvitePage: React.FC = () => {
  const locale = useLocaleString();
  React.useEffect(() => {
    window.location.href = `https://invite.cookierun.com/${locale}/OceanDragon?${window.location.search}`;
  }, []);
  return null;
};

export default InvitePage;
